#hero {
  min-height: 100vh;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-20px);}
}

.hero {
  &__header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  &__typewrite {

  }
  &__toggle {
    width: 2px;
    height: 3rem;
    position: absolute;
    bottom: 3rem;
    left: calc(50% - 1px);
    background: rgba(255,255,255,0.3);
    z-index: 0;
    animation: bounce 4s infinite;

  }


  &__title {
    font-size: 6rem;
    white-space: nowrap;

    &--start {
      @media #{$c-breakpoint-smartphone-lg} {
        display: block;

      }
    }

    @media #{$c-breakpoint-tablet-sm} {
      font-size: 4rem;
      line-height: 1.2;
    }

    @media #{$c-breakpoint-smartphone-lg} {
      font-size: 2.8rem;
      line-height: 1.2;
      white-space: normal;
    }
  }
  &__content {
    font-size: 1.8rem;
    max-width: 54rem;

    @media #{$c-breakpoint-smartphone-lg} {
      font-size: 1.4rem;
    }
  }
}
