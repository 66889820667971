
// Converted Variables

$aqua: #7fdbff !default;
$blue: #0074d9 !default;
$navy: #001f3f !default;
$teal: #39cccc !default;
$green: #2ecc40 !default;
$olive: #3d9970 !default;
$lime: #01ff70 !default;
$yellow: #ffdc00 !default;
$orange: #ff851b !default;
$red: #ff4136 !default;
$fuchsia: #f012be !default;
$purple: #b10dc9 !default;
$maroon: #85144b !default;
$white: #fff !default;
$silver: #ddd !default;
$gray: #aaa !default;
$black: #111 !default;

// Custom Media Query Variables


/* Basscss Background Colors */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/

.bg-black  { background-color: $black }

.bg-gray   { background-color: $gray }

.bg-silver { background-color: $silver }

.bg-white  { background-color: $white }

.bg-aqua  { background-color: $aqua }

.bg-blue  { background-color: $blue }

.bg-navy  { background-color: $navy }

.bg-teal  { background-color: $teal }

.bg-green { background-color: $green }

.bg-olive { background-color: $olive }

.bg-lime  { background-color: $lime }

.bg-yellow  { background-color: $yellow }

.bg-orange  { background-color: $orange }

.bg-red     { background-color: $red }

.bg-fuchsia { background-color: $fuchsia }

.bg-purple  { background-color: $purple }

.bg-maroon  { background-color: $maroon }