@import "https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap";
.h1 {
  font-size: 2rem;
}

.h2 {
  font-size: 1.5rem;
}

.h3 {
  font-size: 1.25rem;
}

.h4 {
  font-size: 1rem;
}

.h5 {
  font-size: .875rem;
}

.h6 {
  font-size: .75rem;
}

.font-family-inherit {
  font-family: inherit;
}

.font-size-inherit {
  font-size: inherit;
}

.text-decoration-none {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.regular {
  font-weight: normal;
}

.italic {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
  letter-spacing: .2em;
}

.left-align {
  text-align: left;
}

.center {
  text-align: center;
}

.right-align {
  text-align: right;
}

.justify {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-wrap: break-word;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 1.125;
}

.line-height-3 {
  line-height: 1.25;
}

.line-height-4 {
  line-height: 1.5;
}

.list-style-none {
  list-style: none;
}

.underline {
  text-decoration: underline;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.list-reset {
  padding-left: 0;
  list-style: none;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.left {
  float: left;
}

.right {
  float: right;
}

.fit {
  max-width: 100%;
}

.max-width-1 {
  max-width: 24rem;
}

.max-width-2 {
  max-width: 32rem;
}

.max-width-3 {
  max-width: 48rem;
}

.max-width-4 {
  max-width: 64rem;
}

.border-box {
  box-sizing: border-box;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top, img {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.m0 {
  margin: 0;
}

.mt0 {
  margin-top: 0;
}

.mr0 {
  margin-right: 0;
}

.mb0 {
  margin-bottom: 0;
}

.ml0 {
  margin-left: 0;
}

.mx0 {
  margin-left: 0;
  margin-right: 0;
}

.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m1 {
  margin: .5rem;
}

.mt1 {
  margin-top: .5rem;
}

.mr1 {
  margin-right: .5rem;
}

.mb1 {
  margin-bottom: .5rem;
}

.ml1 {
  margin-left: .5rem;
}

.mx1 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my1 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.m2 {
  margin: 1rem;
}

.mt2 {
  margin-top: 1rem;
}

.mr2 {
  margin-right: 1rem;
}

.mb2 {
  margin-bottom: 1rem;
}

.ml2 {
  margin-left: 1rem;
}

.mx2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m3 {
  margin: 2rem;
}

.mt3 {
  margin-top: 2rem;
}

.mr3 {
  margin-right: 2rem;
}

.mb3 {
  margin-bottom: 2rem;
}

.ml3 {
  margin-left: 2rem;
}

.mx3 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m4 {
  margin: 4rem;
}

.mt4 {
  margin-top: 4rem;
}

.mr4 {
  margin-right: 4rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.ml4 {
  margin-left: 4rem;
}

.mx4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mxn1 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.mxn2 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mxn3 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.mxn4 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.p0 {
  padding: 0;
}

.pt0 {
  padding-top: 0;
}

.pr0 {
  padding-right: 0;
}

.pb0 {
  padding-bottom: 0;
}

.pl0 {
  padding-left: 0;
}

.px0 {
  padding-left: 0;
  padding-right: 0;
}

.py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p1 {
  padding: .5rem;
}

.pt1 {
  padding-top: .5rem;
}

.pr1 {
  padding-right: .5rem;
}

.pb1 {
  padding-bottom: .5rem;
}

.pl1 {
  padding-left: .5rem;
}

.py1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.p2 {
  padding: 1rem;
}

.pt2 {
  padding-top: 1rem;
}

.pr2 {
  padding-right: 1rem;
}

.pb2 {
  padding-bottom: 1rem;
}

.pl2 {
  padding-left: 1rem;
}

.py2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p3 {
  padding: 2rem;
}

.pt3 {
  padding-top: 2rem;
}

.pr3 {
  padding-right: 2rem;
}

.pb3 {
  padding-bottom: 2rem;
}

.pl3 {
  padding-left: 2rem;
}

.py3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px3 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.p4 {
  padding: 4rem;
}

.pt4 {
  padding-top: 4rem;
}

.pr4 {
  padding-right: 4rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pl4 {
  padding-left: 4rem;
}

.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.col {
  float: left;
  box-sizing: border-box;
}

.col-right {
  float: right;
  box-sizing: border-box;
}

.col-1 {
  width: 8.33333%;
}

.col-2 {
  width: 16.6667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333%;
}

.col-5 {
  width: 41.6667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333%;
}

.col-8 {
  width: 66.6667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333%;
}

.col-11 {
  width: 91.6667%;
}

.col-12 {
  width: 100%;
}

@media (width >= 40em) {
  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }

  .sm-col-1 {
    width: 8.33333%;
  }

  .sm-col-2 {
    width: 16.6667%;
  }

  .sm-col-3 {
    width: 25%;
  }

  .sm-col-4 {
    width: 33.3333%;
  }

  .sm-col-5 {
    width: 41.6667%;
  }

  .sm-col-6 {
    width: 50%;
  }

  .sm-col-7 {
    width: 58.3333%;
  }

  .sm-col-8 {
    width: 66.6667%;
  }

  .sm-col-9 {
    width: 75%;
  }

  .sm-col-10 {
    width: 83.3333%;
  }

  .sm-col-11 {
    width: 91.6667%;
  }

  .sm-col-12 {
    width: 100%;
  }
}

@media (width >= 52em) {
  .md-col {
    float: left;
    box-sizing: border-box;
  }

  .md-col-right {
    float: right;
    box-sizing: border-box;
  }

  .md-col-1 {
    width: 8.33333%;
  }

  .md-col-2 {
    width: 16.6667%;
  }

  .md-col-3 {
    width: 25%;
  }

  .md-col-4 {
    width: 33.3333%;
  }

  .md-col-5 {
    width: 41.6667%;
  }

  .md-col-6 {
    width: 50%;
  }

  .md-col-7 {
    width: 58.3333%;
  }

  .md-col-8 {
    width: 66.6667%;
  }

  .md-col-9 {
    width: 75%;
  }

  .md-col-10 {
    width: 83.3333%;
  }

  .md-col-11 {
    width: 91.6667%;
  }

  .md-col-12 {
    width: 100%;
  }
}

@media (width >= 64em) {
  .lg-col {
    float: left;
    box-sizing: border-box;
  }

  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }

  .lg-col-1 {
    width: 8.33333%;
  }

  .lg-col-2 {
    width: 16.6667%;
  }

  .lg-col-3 {
    width: 25%;
  }

  .lg-col-4 {
    width: 33.3333%;
  }

  .lg-col-5 {
    width: 41.6667%;
  }

  .lg-col-6 {
    width: 50%;
  }

  .lg-col-7 {
    width: 58.3333%;
  }

  .lg-col-8 {
    width: 66.6667%;
  }

  .lg-col-9 {
    width: 75%;
  }

  .lg-col-10 {
    width: 83.3333%;
  }

  .lg-col-11 {
    width: 91.6667%;
  }

  .lg-col-12 {
    width: 100%;
  }
}

.flex {
  display: flex;
}

@media (width >= 40em) {
  .sm-flex {
    display: flex;
  }
}

@media (width >= 52em) {
  .md-flex {
    display: flex;
  }
}

@media (width >= 64em) {
  .lg-flex {
    display: flex;
  }
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-stretch {
  align-content: stretch;
}

.flex-auto {
  flex: auto;
  min-width: 0;
  min-height: 0;
}

.flex-none {
  flex: none;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-last {
  order: 99999;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.z1 {
  z-index: 1;
}

.z2 {
  z-index: 2;
}

.z3 {
  z-index: 3;
}

.z4 {
  z-index: 4;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
}

.border-right {
  border-right-style: solid;
  border-right-width: 1px;
}

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.border-left {
  border-left-style: solid;
  border-left-width: 1px;
}

.border-none {
  border: 0;
}

.rounded {
  border-radius: 3px;
}

.circle {
  border-radius: 50%;
}

.rounded-top {
  border-radius: 3px 3px 0 0;
}

.rounded-right {
  border-radius: 0 3px 3px 0;
}

.rounded-bottom {
  border-radius: 0 0 3px 3px;
}

.rounded-left {
  border-radius: 3px 0 0 3px;
}

.not-rounded {
  border-radius: 0;
}

.hide {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

@media (width <= 40em) {
  .xs-hide {
    display: none !important;
  }
}

@media (width >= 40em) and (width <= 52em) {
  .sm-hide {
    display: none !important;
  }
}

@media (width >= 52em) and (width <= 64em) {
  .md-hide {
    display: none !important;
  }
}

@media (width >= 64em) {
  .lg-hide {
    display: none !important;
  }
}

.display-none {
  display: none !important;
}

.all-initial {
  all: initial;
}

.all-unset {
  all: unset;
}

.all-inherit {
  all: inherit;
}

.bg-black {
  background-color: #111;
}

.bg-gray {
  background-color: #aaa;
}

.bg-silver {
  background-color: #ddd;
}

.bg-white {
  background-color: #fff;
}

.bg-aqua {
  background-color: #7fdbff;
}

.bg-blue {
  background-color: #0074d9;
}

.bg-navy {
  background-color: #001f3f;
}

.bg-teal {
  background-color: #39cccc;
}

.bg-green {
  background-color: #2ecc40;
}

.bg-olive {
  background-color: #3d9970;
}

.bg-lime {
  background-color: #01ff70;
}

.bg-yellow {
  background-color: #ffdc00;
}

.bg-orange {
  background-color: #ff851b;
}

.bg-red {
  background-color: #ff4136;
}

.bg-fuchsia {
  background-color: #f012be;
}

.bg-purple {
  background-color: #b10dc9;
}

.bg-maroon {
  background-color: #85144b;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}

.bg-top {
  background-position: top;
}

.bg-right {
  background-position: 100%;
}

.bg-bottom {
  background-position: bottom;
}

.bg-left {
  background-position: 0;
}

.border-black {
  border-color: #111;
}

.border-gray {
  border-color: #aaa;
}

.border-silver {
  border-color: #ddd;
}

.border-white {
  border-color: #fff;
}

.border-aqua {
  border-color: #7fdbff;
}

.border-blue {
  border-color: #0074d9;
}

.border-navy {
  border-color: #001f3f;
}

.border-teal {
  border-color: #39cccc;
}

.border-green {
  border-color: #2ecc40;
}

.border-olive {
  border-color: #3d9970;
}

.border-lime {
  border-color: #01ff70;
}

.border-yellow {
  border-color: #ffdc00;
}

.border-orange {
  border-color: #ff851b;
}

.border-red {
  border-color: #ff4136;
}

.border-fuchsia {
  border-color: #f012be;
}

.border-purple {
  border-color: #b10dc9;
}

.border-maroon {
  border-color: #85144b;
}

.btn {
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  vertical-align: middle;
  -webkit-appearance: none;
  height: auto;
  color: inherit;
  background-color: #0000;
  border: 1px solid #0000;
  margin: 0;
  padding: .5rem 1rem;
  font-weight: bold;
  line-height: 1.125rem;
  text-decoration: none;
  display: inline-block;
}

.btn:hover {
  text-decoration: none;
}

.btn:focus {
  border-color: #00000020;
  outline: none;
  box-shadow: 0 0 0 3px #00000040;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.btn-outline, .btn-outline:hover {
  border-color: currentColor;
}

.btn-outline {
  border-radius: 3px;
}

.btn-outline:hover {
  box-shadow: inset 0 0 0 20rem #00000010;
}

.btn-outline:active {
  box-shadow: inset 0 0 0 20rem #00000020, inset 0 3px 4px #00000040, 0 0 1px #00000020;
}

.btn-outline:disabled, .btn-outline.is-disabled {
  opacity: .5;
}

.bg-darken-1 {
  background-color: #00000010;
}

.bg-darken-2 {
  background-color: #00000020;
}

.bg-darken-3 {
  background-color: #00000040;
}

.bg-darken-4 {
  background-color: #00000080;
}

.highlight-dark .hljs {
  color: #fff;
  -webkit-text-size-adjust: none;
}

.highlight-dark .hljs-comment, .highlight-dark .diff .hljs-header, .highlight-dark .hljs-javadoc {
  color: #ddd;
  font-style: italic;
}

.highlight-dark .hljs-keyword, .highlight-dark .css .rule .hljs-keyword, .highlight-dark .hljs-winutils, .highlight-dark .nginx .hljs-title, .highlight-dark .hljs-subst, .highlight-dark .hljs-request, .highlight-dark .hljs-status {
  color: #fff;
  font-weight: bold;
}

.highlight-dark .hljs-number, .highlight-dark .hljs-hexcolor, .highlight-dark .ruby .hljs-constant {
  color: #01ff70;
}

.highlight-dark .hljs-string, .highlight-dark .hljs-tag .hljs-value, .highlight-dark .hljs-phpdoc, .highlight-dark .hljs-dartdoc, .highlight-dark .tex .hljs-formula {
  color: #ff4136;
}

.highlight-dark .hljs-list .hljs-keyword, .highlight-dark .hljs-subst {
  font-weight: normal;
}

.highlight-dark .hljs-class .hljs-title, .highlight-dark .hljs-type, .highlight-dark .vhdl .hljs-literal, .highlight-dark .tex .hljs-command {
  color: #7fdbff;
  font-weight: bold;
}

.highlight-dark .hljs-tag, .highlight-dark .hljs-tag .hljs-title, .highlight-dark .hljs-rules .hljs-property, .highlight-dark .django .hljs-tag .hljs-keyword {
  color: #7fdbff;
  font-weight: normal;
}

.highlight-dark .hljs-attribute, .highlight-dark .hljs-variable, .highlight-dark .lisp .hljs-body, .highlight-dark .hljs-regexp {
  color: #01ff70;
}

.highlight-dark .hljs-symbol, .highlight-dark .ruby .hljs-symbol .hljs-string, .highlight-dark .lisp .hljs-keyword, .highlight-dark .clojure .hljs-keyword, .highlight-dark .scheme .hljs-keyword, .highlight-dark .tex .hljs-special, .highlight-dark .hljs-prompt {
  color: #f012be;
}

.highlight-dark .hljs-built_in {
  color: #7fdbff;
}

.highlight-dark .hljs-preprocessor, .highlight-dark .hljs-pragma, .highlight-dark .hljs-pi, .highlight-dark .hljs-doctype, .highlight-dark .hljs-shebang, .highlight-dark .hljs-cdata {
  color: #ddd;
  font-weight: bold;
}

.highlight-dark .hljs-deletion {
  background: #f012be;
}

.highlight-dark .hljs-addition {
  background: #01ff70;
}

.highlight-dark .diff .hljs-change {
  background: #7fdbff;
}

.highlight-dark .hljs-chunk {
  color: #aaa;
}

.hljs {
  -webkit-text-size-adjust: none;
}

.hljs-comment, .diff .hljs-header, .hljs-javadoc {
  color: #ddd;
  font-style: italic;
}

.hljs-keyword, .css .rule .hljs-keyword, .hljs-winutils, .nginx .hljs-title, .hljs-subst, .hljs-request, .hljs-status {
  color: #fff;
  font-weight: bold;
}

.hljs-number, .hljs-hexcolor, .ruby .hljs-constant {
  color: #01ff70;
}

.hljs-string, .hljs-tag .hljs-value, .hljs-phpdoc, .hljs-dartdoc, .tex .hljs-formula {
  color: #ff4136;
}

.hljs-title, .hljs-id, .scss .hljs-preprocessor {
  color: #ff4136;
  font-weight: bold;
}

.hljs-list .hljs-keyword, .hljs-subst {
  font-weight: normal;
}

.hljs-class .hljs-title, .hljs-type, .vhdl .hljs-literal, .tex .hljs-command {
  color: #7fdbff;
  font-weight: bold;
}

.hljs-tag, .hljs-tag .hljs-title, .hljs-rules .hljs-property, .django .hljs-tag .hljs-keyword {
  color: #7fdbff;
  font-weight: normal;
}

.hljs-attribute, .hljs-variable, .lisp .hljs-body, .hljs-regexp {
  color: #01ff70;
}

.hljs-symbol, .ruby .hljs-symbol .hljs-string, .lisp .hljs-keyword, .clojure .hljs-keyword, .scheme .hljs-keyword, .tex .hljs-special, .hljs-prompt {
  color: #f012be;
}

.hljs-built_in {
  color: #7fdbff;
}

.hljs-preprocessor, .hljs-pragma, .hljs-pi, .hljs-doctype, .hljs-shebang, .hljs-cdata {
  color: #ddd;
  font-weight: bold;
}

.hljs-deletion {
  background: #f012be;
}

.hljs-addition {
  background: #01ff70;
}

.diff .hljs-change {
  background: #7fdbff;
}

.hljs-chunk {
  color: #aaa;
}

.media, .sm-media, .md-media, .lg-media {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.media {
  display: flex;
}

.media-center {
  align-items: center;
}

.media-bottom {
  align-items: flex-end;
}

.media-img, .media-body {
  padding-left: .5rem;
  padding-right: .5rem;
}

.media-body {
  flex: auto;
}

@media (width >= 40em) {
  .sm-media {
    display: flex;
  }
}

@media (width >= 52em) {
  .md-media {
    display: flex;
  }
}

@media (width >= 64em) {
  .lg-media {
    display: flex;
  }
}

.progress {
  -webkit-appearance: none;
  background-color: #00000020;
  border: 0;
  border-radius: 10000px;
  width: 100%;
  height: .5625rem;
  margin: .5rem 0;
  display: block;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  -webkit-appearance: none;
  background-color: #00000020;
}

.progress::-webkit-progress-value {
  -webkit-appearance: none;
  background-color: currentColor;
}

.progress::-moz-progress-bar {
  background-color: currentColor;
}

@media (width >= 40em) {
  .sm-m0 {
    margin: 0;
  }

  .sm-mt0 {
    margin-top: 0;
  }

  .sm-mr0 {
    margin-right: 0;
  }

  .sm-mb0 {
    margin-bottom: 0;
  }

  .sm-ml0 {
    margin-left: 0;
  }

  .sm-mx0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm-m1 {
    margin: .5rem;
  }

  .sm-mt1 {
    margin-top: .5rem;
  }

  .sm-mr1 {
    margin-right: .5rem;
  }

  .sm-mb1 {
    margin-bottom: .5rem;
  }

  .sm-ml1 {
    margin-left: .5rem;
  }

  .sm-mx1 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .sm-my1 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .sm-m2 {
    margin: 1rem;
  }

  .sm-mt2 {
    margin-top: 1rem;
  }

  .sm-mr2 {
    margin-right: 1rem;
  }

  .sm-mb2 {
    margin-bottom: 1rem;
  }

  .sm-ml2 {
    margin-left: 1rem;
  }

  .sm-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm-m3 {
    margin: 2rem;
  }

  .sm-mt3 {
    margin-top: 2rem;
  }

  .sm-mr3 {
    margin-right: 2rem;
  }

  .sm-mb3 {
    margin-bottom: 2rem;
  }

  .sm-ml3 {
    margin-left: 2rem;
  }

  .sm-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm-m4 {
    margin: 4rem;
  }

  .sm-mt4 {
    margin-top: 4rem;
  }

  .sm-mr4 {
    margin-right: 4rem;
  }

  .sm-mb4 {
    margin-bottom: 4rem;
  }

  .sm-ml4 {
    margin-left: 4rem;
  }

  .sm-mx4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm-mxn1 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm-ml-auto {
    margin-left: auto;
  }

  .sm-mr-auto {
    margin-right: auto;
  }

  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width >= 52em) {
  .md-m0 {
    margin: 0;
  }

  .md-mt0 {
    margin-top: 0;
  }

  .md-mr0 {
    margin-right: 0;
  }

  .md-mb0 {
    margin-bottom: 0;
  }

  .md-ml0 {
    margin-left: 0;
  }

  .md-mx0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md-m1 {
    margin: .5rem;
  }

  .md-mt1 {
    margin-top: .5rem;
  }

  .md-mr1 {
    margin-right: .5rem;
  }

  .md-mb1 {
    margin-bottom: .5rem;
  }

  .md-ml1 {
    margin-left: .5rem;
  }

  .md-mx1 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md-my1 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .md-m2 {
    margin: 1rem;
  }

  .md-mt2 {
    margin-top: 1rem;
  }

  .md-mr2 {
    margin-right: 1rem;
  }

  .md-mb2 {
    margin-bottom: 1rem;
  }

  .md-ml2 {
    margin-left: 1rem;
  }

  .md-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md-m3 {
    margin: 2rem;
  }

  .md-mt3 {
    margin-top: 2rem;
  }

  .md-mr3 {
    margin-right: 2rem;
  }

  .md-mb3 {
    margin-bottom: 2rem;
  }

  .md-ml3 {
    margin-left: 2rem;
  }

  .md-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md-m4 {
    margin: 4rem;
  }

  .md-mt4 {
    margin-top: 4rem;
  }

  .md-mr4 {
    margin-right: 4rem;
  }

  .md-mb4 {
    margin-bottom: 4rem;
  }

  .md-ml4 {
    margin-left: 4rem;
  }

  .md-mx4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md-mxn1 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .md-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md-ml-auto {
    margin-left: auto;
  }

  .md-mr-auto {
    margin-right: auto;
  }

  .md-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width >= 64em) {
  .lg-m0 {
    margin: 0;
  }

  .lg-mt0 {
    margin-top: 0;
  }

  .lg-mr0 {
    margin-right: 0;
  }

  .lg-mb0 {
    margin-bottom: 0;
  }

  .lg-ml0 {
    margin-left: 0;
  }

  .lg-mx0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg-m1 {
    margin: .5rem;
  }

  .lg-mt1 {
    margin-top: .5rem;
  }

  .lg-mr1 {
    margin-right: .5rem;
  }

  .lg-mb1 {
    margin-bottom: .5rem;
  }

  .lg-ml1 {
    margin-left: .5rem;
  }

  .lg-mx1 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg-my1 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .lg-m2 {
    margin: 1rem;
  }

  .lg-mt2 {
    margin-top: 1rem;
  }

  .lg-mr2 {
    margin-right: 1rem;
  }

  .lg-mb2 {
    margin-bottom: 1rem;
  }

  .lg-ml2 {
    margin-left: 1rem;
  }

  .lg-mx2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg-my2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg-m3 {
    margin: 2rem;
  }

  .lg-mt3 {
    margin-top: 2rem;
  }

  .lg-mr3 {
    margin-right: 2rem;
  }

  .lg-mb3 {
    margin-bottom: 2rem;
  }

  .lg-ml3 {
    margin-left: 2rem;
  }

  .lg-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg-m4 {
    margin: 4rem;
  }

  .lg-mt4 {
    margin-top: 4rem;
  }

  .lg-mr4 {
    margin-right: 4rem;
  }

  .lg-mb4 {
    margin-bottom: 4rem;
  }

  .lg-ml4 {
    margin-left: 4rem;
  }

  .lg-mx4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg-my4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg-mxn1 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .lg-mxn2 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg-mxn3 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg-mxn4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg-ml-auto {
    margin-left: auto;
  }

  .lg-mr-auto {
    margin-right: auto;
  }

  .lg-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width >= 40em) {
  .sm-p0 {
    padding: 0;
  }

  .sm-pt0 {
    padding-top: 0;
  }

  .sm-pr0 {
    padding-right: 0;
  }

  .sm-pb0 {
    padding-bottom: 0;
  }

  .sm-pl0 {
    padding-left: 0;
  }

  .sm-px0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm-p1 {
    padding: .5rem;
  }

  .sm-pt1 {
    padding-top: .5rem;
  }

  .sm-pr1 {
    padding-right: .5rem;
  }

  .sm-pb1 {
    padding-bottom: .5rem;
  }

  .sm-pl1 {
    padding-left: .5rem;
  }

  .sm-px1 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm-py1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm-p2 {
    padding: 1rem;
  }

  .sm-pt2 {
    padding-top: 1rem;
  }

  .sm-pr2 {
    padding-right: 1rem;
  }

  .sm-pb2 {
    padding-bottom: 1rem;
  }

  .sm-pl2 {
    padding-left: 1rem;
  }

  .sm-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm-p3 {
    padding: 2rem;
  }

  .sm-pt3 {
    padding-top: 2rem;
  }

  .sm-pr3 {
    padding-right: 2rem;
  }

  .sm-pb3 {
    padding-bottom: 2rem;
  }

  .sm-pl3 {
    padding-left: 2rem;
  }

  .sm-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm-p4 {
    padding: 4rem;
  }

  .sm-pt4 {
    padding-top: 4rem;
  }

  .sm-pr4 {
    padding-right: 4rem;
  }

  .sm-pb4 {
    padding-bottom: 4rem;
  }

  .sm-pl4 {
    padding-left: 4rem;
  }

  .sm-px4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (width >= 52em) {
  .md-p0 {
    padding: 0;
  }

  .md-pt0 {
    padding-top: 0;
  }

  .md-pr0 {
    padding-right: 0;
  }

  .md-pb0 {
    padding-bottom: 0;
  }

  .md-pl0 {
    padding-left: 0;
  }

  .md-px0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md-p1 {
    padding: .5rem;
  }

  .md-pt1 {
    padding-top: .5rem;
  }

  .md-pr1 {
    padding-right: .5rem;
  }

  .md-pb1 {
    padding-bottom: .5rem;
  }

  .md-pl1 {
    padding-left: .5rem;
  }

  .md-px1 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md-py1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md-p2 {
    padding: 1rem;
  }

  .md-pt2 {
    padding-top: 1rem;
  }

  .md-pr2 {
    padding-right: 1rem;
  }

  .md-pb2 {
    padding-bottom: 1rem;
  }

  .md-pl2 {
    padding-left: 1rem;
  }

  .md-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md-p3 {
    padding: 2rem;
  }

  .md-pt3 {
    padding-top: 2rem;
  }

  .md-pr3 {
    padding-right: 2rem;
  }

  .md-pb3 {
    padding-bottom: 2rem;
  }

  .md-pl3 {
    padding-left: 2rem;
  }

  .md-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md-p4 {
    padding: 4rem;
  }

  .md-pt4 {
    padding-top: 4rem;
  }

  .md-pr4 {
    padding-right: 4rem;
  }

  .md-pb4 {
    padding-bottom: 4rem;
  }

  .md-pl4 {
    padding-left: 4rem;
  }

  .md-px4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (width >= 64em) {
  .lg-p0 {
    padding: 0;
  }

  .lg-pt0 {
    padding-top: 0;
  }

  .lg-pr0 {
    padding-right: 0;
  }

  .lg-pb0 {
    padding-bottom: 0;
  }

  .lg-pl0 {
    padding-left: 0;
  }

  .lg-px0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg-p1 {
    padding: .5rem;
  }

  .lg-pt1 {
    padding-top: .5rem;
  }

  .lg-pr1 {
    padding-right: .5rem;
  }

  .lg-pb1 {
    padding-bottom: .5rem;
  }

  .lg-pl1 {
    padding-left: .5rem;
  }

  .lg-px1 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg-py1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg-p2 {
    padding: 1rem;
  }

  .lg-pt2 {
    padding-top: 1rem;
  }

  .lg-pr2 {
    padding-right: 1rem;
  }

  .lg-pb2 {
    padding-bottom: 1rem;
  }

  .lg-pl2 {
    padding-left: 1rem;
  }

  .lg-px2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg-py2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg-p3 {
    padding: 2rem;
  }

  .lg-pt3 {
    padding-top: 2rem;
  }

  .lg-pr3 {
    padding-right: 2rem;
  }

  .lg-pb3 {
    padding-bottom: 2rem;
  }

  .lg-pl3 {
    padding-left: 2rem;
  }

  .lg-px3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg-py3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg-p4 {
    padding: 4rem;
  }

  .lg-pt4 {
    padding-top: 4rem;
  }

  .lg-pr4 {
    padding-right: 4rem;
  }

  .lg-pb4 {
    padding-bottom: 4rem;
  }

  .lg-pl4 {
    padding-left: 4rem;
  }

  .lg-px4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg-py4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.slick-slider {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

#main-header {
  z-index: 1000;
}

#primary-menu {
  margin: 0;
  padding: 0;
  display: inline-block;
}

#primary-menu li {
  margin: 0 1rem;
  padding: 0;
  display: inline-block;
}

#primary-menu li a {
  color: #fff;
  font-size: 1.4rem;
  text-decoration: none;
}

@media (width <= 650px) {
  #primary-menu {
    display: none;
  }
}

.button {
  letter-spacing: 2px;
  color: #fff;
  text-transform: uppercase;
  will-change: auto;
  background-image: linear-gradient(-163deg, #f55648 0%, #d02868 89%);
  border-radius: 100px;
  padding: 1rem 2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 3px 34px #0000004d;
}

.button:hover {
  text-decoration: none;
  box-shadow: 0 3px 3px #0003;
}

#full-width-slideshow .slides article {
  position: relative;
}

#full-width-slideshow .slides article img {
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

#full-width-slideshow .slides article header {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

#full-width-slideshow .slides article header * {
  color: #fff;
}

#full-width-slideshow .slides .slick-arrow, #full-width-slideshow .slides .slick-dots {
  z-index: 10;
  position: absolute;
}

#full-width-slideshow .slides .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}

#full-width-slideshow .slides .slick-arrow.slick-next {
  right: 4rem;
}

#full-width-slideshow .slides .slick-arrow.slick-prev {
  left: 4rem;
}

#full-width-slideshow .slides .slick-dots {
  text-align: center;
  margin: 0;
  bottom: 3rem;
  left: 0;
  right: 0;
}

#full-width-slideshow .slides .slick-dots li {
  margin: 0 1rem;
  display: inline-block;
}

#full-width-slideshow .slides .slick-dots li button {
  opacity: .4;
  background: #fff;
  border: 0;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  margin: 0;
  padding: 0;
  font-size: 0;
}

#full-width-slideshow .slides .slick-dots li.slick-active button {
  opacity: 1;
}

.footer__info a {
  color: #fff;
}

.footer__info .footer__policy-link {
  padding: .5rem;
  display: inline-block;
}

html {
  background: #23202e;
  font-size: 15px;
}

body {
  color: #ffffffb3;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #23202e url("page_background.db9af321.jpg") top / 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Kumbh Sans, sans-serif;
  font-size: 15px;
  line-height: 1.4;
  overflow-x: hidden;
}

body:before {
  content: " ";
  height: 10vw;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#23202e", endColorstr= "#0023202e", GradientType= 0);
  z-index: 999;
  pointer-events: none;
  background: linear-gradient(#23202e 0%, #23202e00 100%);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (width <= 650px) {
  body:before {
    height: 50vw;
  }
}

* {
  box-sizing: border-box;
}

ul, ul li {
  list-style-type: disc;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  padding: 0;
}

p.p1 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 700;
}

h2 {
  font-size: 2.6rem;
}

@media (width <= 650px) {
  h2 {
    font-size: 1.3rem;
    line-height: 1.2;
  }
}

h3 {
  font-size: 1.8rem;
  font-weight: 400;
}

@media (width <= 650px) {
  h3 {
    font-size: 1.4rem;
    line-height: 1.2;
  }
}

img.cover-image {
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center;";
}

.max-width-5 {
  max-width: 78rem;
}

.max-width-6 {
  max-width: 86rem;
}

#main > .container {
  min-height: 500px;
}

.typed-cursor {
  color: #ffffff4d;
  font-weight: normal;
}

.section__title {
  position: relative;
}

.section__title:after {
  content: " ";
  background: #ffffff4d;
  border-radius: 4px;
  width: 3rem;
  height: 4px;
  display: block;
  position: absolute;
  top: -2rem;
}

.section__title.center:after {
  left: 50%;
  transform: translateX(-50%);
}

.work__title {
  font-size: 1.8rem;
  font-weight: 400;
}

@media (width <= 650px) {
  .work__title {
    font-size: 1.4rem;
  }
}

.work__image {
  box-shadow: 0 0 50px #0006;
}

.work__image--mask {
  z-index: 10;
  position: relative;
  overflow: hidden;
}

.work__image--ipad {
  width: 94%;
  padding-top: 6%;
  padding-left: 6%;
  position: absolute;
  top: 0;
}

.work__image--iphone {
  width: 97%;
  padding-top: 19%;
  padding-left: 5%;
  position: absolute;
  top: 0;
}

.work__image--wrapper-ipad {
  will-change: auto;
  max-width: 30%;
  transition: all .6s;
  position: absolute;
  bottom: -8%;
  left: -5%;
}

.work:hover .work__image--wrapper-ipad {
  left: -8%;
}

.work__image--wrapper-iphone {
  will-change: auto;
  max-width: 15%;
  transition: all .6s;
  position: absolute;
  bottom: -8%;
  right: -5%;
}

.work:hover .work__image--wrapper-iphone {
  right: -8%;
}

.work__image--wrapper-ipad, .work__image--wrapper-iphone {
  filter: drop-shadow(0 0 5px #0006);
}

.footer__main {
  border-top: 1px solid #fff3;
}

.page.type-page {
  margin-top: 10rem;
  margin-bottom: 6rem;
}

.page.type-page .page__content a {
  color: #fff;
}

.page.type-page .page__content table, .page.type-page .page__content th, .page.type-page .page__content td {
  border-collapse: collapse;
  border: 1px solid #fff6;
}

.page.type-page .page__content table th, .page.type-page .page__content table td, .page.type-page .page__content th th, .page.type-page .page__content th td, .page.type-page .page__content td th, .page.type-page .page__content td td {
  padding: .25rem;
}

.iub__us-widget {
  display: none !important;
}

#hero {
  min-height: 100vh;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-20px);
  }
}

.hero__header {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero__toggle {
  z-index: 0;
  background: #ffffff4d;
  width: 2px;
  height: 3rem;
  animation: 4s infinite bounce;
  position: absolute;
  bottom: 3rem;
  left: calc(50% - 1px);
}

.hero__title {
  white-space: nowrap;
  font-size: 6rem;
}

@media (width <= 650px) {
  .hero__title--start {
    display: block;
  }
}

@media (width <= 990px) {
  .hero__title {
    font-size: 4rem;
    line-height: 1.2;
  }
}

@media (width <= 650px) {
  .hero__title {
    white-space: normal;
    font-size: 2.8rem;
    line-height: 1.2;
  }
}

.hero__content {
  max-width: 54rem;
  font-size: 1.8rem;
}

@media (width <= 650px) {
  .hero__content {
    font-size: 1.4rem;
  }
}
/*# sourceMappingURL=index.css.map */
