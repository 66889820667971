#main-header {
    z-index: 1000;
}

#primary-menu {
  display: inline-block;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    display: inline-block;
    margin: 0 1rem;
    a {
      color: white;
      font-size: 1.4rem;
      text-decoration: none;
    }
  }

  @media #{$c-breakpoint-smartphone-lg} {
    display: none;
  }
}
